import BasePlugin from '../BasePlugin'

export default class AddInvestmentBudgetIndicators extends BasePlugin {
  async execute () {
    let investmentBudgetId = this.context.getModel().id
    let data = { 'recordId': investmentBudgetId, 'objectId': 179 }
    let cont = this.context
    cont.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddInvestmentBudgetIndicators`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(function () {
      cont.getDashboardComponents()['component_dd5282b5-5ef2-4835-b5a0-1e3eb06db093'][0].loadData()
    })
  }
}
